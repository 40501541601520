/** @jsxImportSource theme-ui */

import { Link } from "gatsby";
import { BaseStyles } from "theme-ui";
import ExternalLink from "../components/external-link";
import Global from "../components/global";
import { Header } from "../components/header";
import Main from "../components/main";
import Seo from "../components/seo";
import Wrapper from "../components/wrapper";

const TITLE = "リンク";

export const Head = () => <Seo title={TITLE} article={true} />;

const Links = () => {
  return (
    <>
      <Global />
      <Header />
      <Main>
        <Wrapper>
          <BaseStyles>
            <h1 style={{ marginBottom: "2.0rem" }}>{TITLE}</h1>
            <div sx={{ marginTop: "2.0rem", marginBottom: "2.0rem" }}>
              <ul sx={list}>
                <li>
                  <span sx={name}>
                    <ExternalLink url="http://www.kanagawa-folklore.info/index.html">神奈川県民俗芸能保存協会</ExternalLink>
                  </span>
                  <br />
                  <div sx={description}>
                    「民俗芸能情報」のページに、神奈川県内の民俗芸能の一覧が掲載されています。<br />
                    郷土芸能大会なども多数含まれています。
                  </div>
                </li>
                <li>
                  <span sx={name}>
                    <ExternalLink url="https://www.pref.chiba.lg.jp/kyouiku/bunkazai/fukyu.html">
                      千葉県教育委員会 文化財へGO! （千葉県のWebサイト内）
                    </ExternalLink>
                  </span>
                  <br />
                  <div sx={description}>
                    ページ内の「無形民俗文化財の公開」において、千葉県内の国指定・県指定民俗文化財の公開スケジュールが掲載されています。<br />
                    千葉県内の民俗芸能大会の一覧も記載されています。
                  </div>
                </li>
                <li>
                  <span sx={name}>
                    <ExternalLink url="https://www.syougai.metro.tokyo.lg.jp/sesaku/week.html">東京都生涯学習情報 東京文化財ウィーク情報</ExternalLink>
                  </span>
                  <br />
                  <div sx={description}>
                    東京文化財ウィーク（10月下旬～11月上旬）のパンフレットが公開されています。<br />
                    企画事業の一部に民俗芸能大会などが含まれています。
                  </div>
                </li>
                <li>
                  <span sx={name}>
                    <ExternalLink url="https://tonbitoyosuke.at.webry.info/">鳶と与助</ExternalLink>
                  </span>
                  <br />
                  <div sx={description}>
                    東京都下およびその近郊における祭囃子などの予定と見聞録がまとめられているブログです。<br />
                    郷土芸能大会の情報も多く含まれています。<br />
                    更新が非常にタイムリーで、いつも参考にさせていただいてます。
                  </div>
                </li>
              </ul>
            </div>
            <div>
              <Link to="/">トップページに戻る</Link>
            </div>
          </BaseStyles>
        </Wrapper>
      </Main>
    </>
  );
};

export default Links;

const list = {
  listStyleType: "disc",

  "li": {
    marginLeft: "1.5rem",
    marginBottom: "1.5rem",
  },
};

const name = {
  fontSize: "1.2rem",
};

const description = {
  marginTop: "0.5rem",
};
